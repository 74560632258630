<template>
  <div class="bg">
    <div class="imgBox">
      <img class="bgimg" src="../../assets/image/bg.png" />
    </div>

    <div class="center">
      <div class="container">
        <div class="login">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="login"> 账户登录 </a-menu-item>
          </a-menu>
          <div v-if="current[0] === 'CAlogin'" class="psw">
            <div class="ukey">
              <img src="../../assets/image/ukey.png" />
              请插入颁发的UKEY，一键登录
            </div>
            <div class="kong" />
            <a-button type="primary" @click="login"> CA登录 </a-button>
            <div class="tip">
              <span>
                首次登录需安装CA驱动程序，
                <a
                  href="https://download.bqpoint.com/download/downloaddetail.html?SourceFrom=Ztb&ZtbSoftXiaQuCode=010501&ZtbSoftType=DR"
                  target="_blank"
                  >下载驱动</a
                >， 安装后请使用IE浏览器登录，
                <a href="/api/insure/index/download?type=manual" target="_blank"
                  >操作手册</a
                ><br />
                点击无反应，请点此
                <a
                  href="https://zhidao.bqpoint.com/epointknow2/bqepointknowquestion.html?producttype=1&platformguid=f2797119-c3c5-4991-9fe8-f0a92eee0ad2&areacode=320000&wd=%E7%BC%93%E5%AD%98&QuestionGuid=a6349467-554c-4e42-a0f6-7bc2d26d7991"
                  target="_blank"
                  >清理ie缓存</a
                ><br />
                <a
                  href="https://zhidao.bqpoint.com/epointknow2/bqepointknowquestion.html?producttype=1&platformguid=f2797119-c3c5-4991-9fe8-f0a92eee0ad2&areacode=320000"
                  target="_blank"
                  >常见问题解答</a
                >
              </span>
            </div>
          </div>
          <div v-if="current[0] === 'login'" class="psw">
            <a-input
              v-model="form.account"
              placeholder="请输入您的手机号码/用户名"
              style="width: 270px; margin: 5px auto"
            />
            <a-input-password
              @keyup.enter="login"
              v-model="form.password"
              placeholder="请输入您的登录密码"
              style="width: 270px; margin: 5px auto"
            />
            <!-- <a class="pswLogin" @click="toPhoneLogin"> 手机号码登陆 </a> -->
            <a-button type="primary" @click="login"> 立即登录 </a-button>
            <div class="regFor">
              <span @click="forgot">忘记密码？</span>
              <span @click="regis">用户注册</span>
            </div>
          </div>
          <div v-if="current[0] === 'phone'" class="psw">
            <a-input
              v-model="form.phoneNum"
              placeholder="请输入您的手机号码"
              style="width: 270px; margin: 5px auto"
            />
            <div class="phoneCode">
              <a-input
                @keyup.enter="login"
                v-model="form.code"
                placeholder="请输入验证码"
                style="width: 270px"
              />
              <img :src="codeImg" alt="" @click="getImgcode" />
            </div>
            <div class="phoneCode">
              <a-input
                @keyup.enter="login"
                v-model="form.phCode"
                placeholder="请输入短信验证码"
                style="width: 270px"
              />
              <a v-if="isTime" class="phcode" @click="getCode"> 获取验证码 </a>
              <span v-else>{{ time }}s重新获取</span>
            </div>
            <a class="pswLogin" @click="toMsgLogin"> 账号密码登陆 </a>
            <a-button type="primary" @click="login"> 立即登录 </a-button>
            <div class="regFor">
              <span @click="forgot">忘记密码？</span>
              <span @click="regis">用户注册</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      title="修改密码"
      :visible="updatepwd"
      @ok="handleUpdatePwd"
      @cancel="updatepwd = false"
      cancel-text="取消"
      ok-text="确定"
      :maskClosable="false"
    >
      <!-- 密码 -->
      <a-spin :spinning="spinPassword">
        <div
          style="
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            position: relative;
          "
        >
          <!-- <span style="width: 80px; text-align: right; margin-right: 14px"
            >手机号码</span
          ><a-input
            v-model="phoneNum"
            disabled
            style="width: 330px; background: #ffffff; border-radius: 3px"
          /> -->
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            position: relative;
          "
        >
          <span style="width: 80px; text-align: right; margin-right: 14px"
            >旧密码</span
          ><a-input-password
            v-model="oldPwd"
            placeholder="请输入旧密码"
            style="width: 330px; background: #ffffff; border-radius: 3px"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            position: relative;
          "
        >
          <span style="width: 80px; text-align: right; margin-right: 14px"
            >新密码</span
          ><a-input-password
            v-model="newPwd"
            placeholder="密码须8-16位且包含数字、大小写字母"
            style="width: 330px; background: #ffffff; border-radius: 3px"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            margin-bottom: 64px;
            position: relative;
          "
        >
          <span style="width: 80px; text-align: right; margin-right: 14px"
            >确认新密码</span
          ><a-input-password
            v-model="conNewPwd"
            placeholder="再次输入新密码"
            style="width: 330px; background: #ffffff; border-radius: 3px"
          />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { loginCA, login, loginCode } from "@/api/login/register.js";
import { updatePwd } from "@/api/login/register.js";
import { graphicsCode, smsCode } from "@/api/code.js";
import { info } from "@/api/user/user.js";
import { ReadCert } from "@/utils/CAlogin.js";
import { Modal } from "ant-design-vue";
const querystring = require("querystring");

export default {
  name: "Login",
  inject: ["reload"],
  data() {
    return {
      form: {},
      current: ["login"],
      codeImg: "",
      timer: "",
      isTime: true,
      time: 10,
      updatepwd: false,
      spinPassword: false,
      oldPwd: "",
      phoneNum: "",
      newPwd: "",
      conNewPwd: ""
    };
  },
  mounted() {
    this.info();
    this.getImgcode();
  },
  methods: {
    handleUpdatePwd() {
      this.spinPassword = true;
      updatePwd({
        phoneNum: this.phoneNum,
        pwd: this.newPwd,
        confirmPwd: this.conNewPwd,
        oldPwd: this.oldPwd
      }).then(res => {
        this.spinPassword = false;
        if (res.data.status === "FAIL") {
          this.$message.warning(res.data.errorMsg);
        } else {
          this.$message.success("修改成功");
          this.form.password = "";
          this.updatepwd = false;
        }
      });
    },
    isIE() {
      const ie11 = (() => "ActiveXObject" in window)();
      if (!ie11) {
        Modal.info({
          title: "提示",
          okText: "取消",
          content:
            "系统检测到您正在使用非IE浏览器，因系统使用的部分第三方控件不支持非IE浏览器，为保障您的正常操作，建议使用IE浏览器！",
          onOk() {
            console.log("ok");
          }
        });
      }
    },
    getCode() {
      this.timer = "";
      if (
        this.form.code !== "" &&
        this.form.phoneNum !== "" &&
        !!this.form.code
      ) {
        smsCode({
          source: "ACC_USER_LOGIN",
          checkCode: this.form.code,
          phoneNum: this.form.phoneNum
        }).then(res => {
          this.isCode = res.data;
          if (res.data.status === "FAIL") {
            this.$message.warning(this.isCode.errorMsg);
          } else {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning("手机号码和图形验证码不为空");
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 10;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    getImgcode() {
      graphicsCode({ source: "ACC_USER_LOGIN" }).then(res => {
        this.codeImg = window.URL.createObjectURL(res.data);
      });
    },
    toPhoneLogin() {
      this.$set(this.current, 0, "phone");
    },
    toMsgLogin() {
      this.$set(this.current, 0, "login");
    },
    info() {
      info().then(res => {
        if (res.data.status === "SUCCESS") {
          if (window.location.search !== "") {
            const packageId = querystring.parse(
              window.location.search.split("?")[1]
            ).packageId;
            if (packageId !== "") {
              sessionStorage.setItem(
                "projectSectionId",
                JSON.stringify(packageId)
              );
              this.$router.push({ name: "Project", query: { count: 2 } });
            }
          }
        }
      });
    },
    login() {
      console.log(1);
      if (this.current[0] === "login") {
        login({
          account: this.form.account,
          pwd: this.form.password
        }).then(res => {
          console.log("////", );
          if (res.data.status === "FAIL") {
            if (res.data.errorCode === "1001") {
              this.phoneNum = this.form.account;
              this.newPwd = "";
              this.conNewPwd = "";
              this.updatepwd = true;
            }
            console.log(res);
            this.$message.warning(res.data.errorMsg);
          } else {
            sessionStorage.setItem(
              "telephone",
              JSON.stringify(res.data.data.user.phoneNum)
            );
            localStorage.setItem("token", res.data.data.token);
            this.reload();
            if (window.location.search !== "") {
              // const packageId = querystring.parse(window.location.search.split('?')[1]).packageId;
              // sessionStorage.setItem('projectSectionId', JSON.stringify(packageId));
              // this.$router.push({ name: 'Project', query: { count: 2 } });
              // this.$router.push({ path: '/orderDetail', query: { count: 2 } });
            } else {
              // 企业认证
              if (res.data.data.user.certificationFlag == 0) {
                this.$router.push({ name: "entCertForm" });
              } else {
                this.$router.push("/insure/project/view");
              }
            }
          }
        });
      } else if (this.current[0] === "CAlogin") {
        loginCA({ data: ReadCert() }).then(res => {
          if (res.data.status === "SUCCESS") {
            localStorage.setItem("token", res.data.data.token);
            console.log("1111----123");

            this.$router.push("/insure/project/view");
          }
        });
      } else if (this.current[0] === "phone") {
        if (this.form.phCode === "" || !this.form.phCode) {
          return this.$message.warning("短信验证码不能为空");
        }
        const params = {
          phoneNum: this.form.phoneNum,
          phoneCode: this.form.phCode
        };
        loginCode(params).then(res => {
          if (res.data.status === "FAIL") {
            this.$message.warning(res.data.errorMsg);
          } else {
            sessionStorage.setItem(
              "telephone",
              JSON.stringify(this.form.phoneNum)
            );
            localStorage.setItem("token", res.data.data.token);
            this.reload();
            if (window.location.search !== "") {
              const packageId = querystring.parse(
                window.location.search.split("?")[1]
              ).packageId;
              sessionStorage.setItem(
                "projectSectionId",
                JSON.stringify(packageId)
              );
              console.log("---");

              this.$router.push({
                path: "/insure/project/view",
                query: { count: 2 }
              });
            } else {
              console.log("----123");
              this.$router.push("/insure/project/view");
            }
          }
        });
      }
    },
    forgot() {
      this.$router.push({ name: "forgotPassword" });
    },
    regis() {
      this.$router.push({ name: "register" });
    },
    clickLogo() {
      if (
        this.$route.path === "/login" ||
        this.$route.path === "/insure/project/view"
      ) {
        return false;
      } else if (
        this.$route.path === "/forgotPassword" ||
        this.$route.path === "/register"
      ) {
        this.$router.push({ name: "login" });
      } else {
        this.$router.push({ name: "Project" });
      }
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding-top: 0;
}

.imgBox {
  position: relative;
  display: flex;
  justify-content: center;
}

.bgimg {
  height: 830px;
  background-color: #10284c;
}

.center {
  margin: 0 auto;
}

.container {
  position: absolute;
  top: 36vh;
  right: 18vw;
  padding-bottom: 30px;
  width: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(12, 31, 47, 0.2);
  border-radius: 5px;

  .login {
    text-align: center;

    /deep/ .ant-menu-horizontal {
      width: 270px;
      margin: 15px auto;
    }

    /deep/ .ant-menu-item {
      padding: 0 31px;
    }

    .loginTitle {
      width: 270px;
      height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin: 10px auto;
      margin-top: 34px;
    }
    .psw {
      display: flex;
      flex-direction: column;

      /deep/ .ant-input {
        text-align: left;
        height: 42px;
        border: 1px solid #eaeaea;
        border-radius: 3px;
      }

      .phoneCode {
        position: relative;
        margin: 5px auto;

        .phcode {
          position: absolute;
          width: 84px;
          transform: translate(-85px, 8px);
          cursor: pointer;
        }

        span {
          position: absolute;
          width: 90px;
          transform: translate(-90px, 8px);
          color: #aaa;
        }

        img {
          width: 84px;
          height: 27px;
          position: absolute;
          transform: translate(-90px, 8px);
          background: #fff;
          cursor: pointer;
        }
      }

      .pswLogin {
        text-align: right;
        margin-right: 30px;
      }

      .ukey {
        width: 270px;
        margin: 40px auto;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;

        img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }

      .kong {
        height: 23px;
      }

      button {
        width: 270px;
        height: 44px;
        background: #007ce2;
        box-shadow: 0px 5px 8px 0px rgba(136, 189, 232, 0.3);
        border-radius: 3px;
        margin: 10px auto;
        margin-top: 20px;
      }

      .regFor {
        width: 270px;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 7px;
          cursor: pointer;
        }
      }

      .tip {
        width: 270px;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }

        .down {
          cursor: pointer;
          color: #007ce2;
        }
      }
    }
  }
}
/deep/ .anticon {
  display: block;
  margin-top: 20px;
}
/deep/ .ant-modal {
  margin-top: 40px;
}
</style>
